
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





















































.survey-header {
  margin-bottom: 0;
}

.survey-sub-header {
  font-size: $--jb-font-size-small;
  margin-bottom: 32px;
  margin-top: 8px;
}

.time-copy {
  font-size: 13px;
  margin-top: 0;
  text-align: center;
  position: absolute;
  margin-bottom: 32px;
  width: 100%;
}
