
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






























.survey-splash-item-component {
  margin: 0 0 16px 0;

  .survey-splash-media-icon-object {
    align-items: $--jb-media-icon-align;
    display: $--jb-media-icon-flex;
    margin-left: $--jb-media-icon-margin;
    padding: $--jb-media-icon-padding;
  }

  &__icon {
    color: $--color-primary;
    font-size: $--jb-icon-checkmark-size;
  }

  .survey-splash-item-component__body {
    line-height: $--jb-media-icon-right-line-height;
    padding-left: $--jb-media-icon-right-padding;
    text-align: $--jb-media-icon-right-text-align;

    .survey-splash-item-component__name {
      color: $--jb-black-primary-color;
      margin: 0 0 5px;
    }

    .survey-splash-item-component__description {
      color: $--jb-black-primary-color;
      font-size: $--jb-media-icon-description;
      margin: 0;
    }
  }
}
